export default function MNavbar2({categ}) {
    return (
<nav className="navbar navbar-transparent navbar-color-on-scroll fixed-top navbar-expand-lg" color-on-scroll="100" id="sectionsNav">
    <div className="container">
      <div className="navbar-translate">
        <a className="navbar-brand" href="/">
          Inapoi
        </a>
        <a className="btn btn-primary btn-link navbar-brand" href="/proiecte/plasma" style={{paddingRight:7+"px", color: '#F40009', textShadow: "-1px -1px 0 #FFFFFF, 1px -1px 0 #FFFFFF, -1px 1px 0 #FFFFFF, 1px 1px 0 #FFFFFF"}}>
          Plasma
        </a>
        <a className="btn btn-primary btn-link navbar-brand" href="/proiecte/fier" style={{paddingRight:7+"px", color: '#F40009', textShadow: "-1px -1px 0 #FFFFFF, 1px -1px 0 #FFFFFF, -1px 1px 0 #FFFFFF, 1px 1px 0 #FFFFFF"}}>
          Fier
        </a>
        <a className="btn btn-primary btn-link navbar-brand" href="/proiecte/inox" style={{color: '#F40009', textShadow: "-1px -1px 0 #FFFFFF, 1px -1px 0 #FFFFFF, -1px 1px 0 #FFFFFF, 1px 1px 0 #FFFFFF"}}>
          Inox
        </a>
          <button className="navbar-toggler navbar-toggler" type="button" data-toggle="collapse" data-target="#navigation" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
          <span className="sr-only">Schimba modul de navigare</span>
          <span className="navbar-toggler-icon"></span>
          <span className="navbar-toggler-icon"></span>
          <span className="navbar-toggler-icon"></span>
        </button>
      </div>
      <div className="collapse navbar-collapse" id="navigation">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <a className="nav-link" data-placement="bottom" href="javascript:void(0)" rel="nofollow">
              Str Viitorului Nr 1 A
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" rel="tooltip" title="Contact" data-placement="bottom" onClick={() => window.open('tel:0743207067')} data-original-title="Contacteaza-ne" rel="nofollow">
              <i className="material-icons">contact_support</i>
              Contact
            </a>
          </li>
          <li className="dropdown nav-item">
            <a href="#" className="dropdown-toggle nav-link" data-toggle="dropdown">
              <i className="material-icons">apps</i> Categorii
            </a>
            <div className="dropdown-menu dropdown-with-icons">
              <a href="/proiecte/fier" className="dropdown-item">
                Fier
              </a>
              <a href="/proiecte/inox" className="dropdown-item">
                Inox
              </a>
              <a href="/proiecte/plasma" className="dropdown-item">
                Plasma
              </a>
            </div>
          </li>
          <li className="nav-item">
            <a className="nav-link" rel="tooltip" title="Da-ne un like pe Facebook" data-placement="bottom" href="https://m.facebook.com/pages/category/Home-Improvement/Atelier-confectii-metalice-Tulcea-1872073759724925/?locale2=ro_RO" target="_blank" data-original-title="Da-ne un like pe Facebook" rel="nofollow">
              <i className="fa fa-facebook-square"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
    )
}
