import React from 'react'
import Fier from './Componente/Fier'
import Inox from './Componente/Inox'
import Plasma from './Componente/Plasma'
import Despre from './Despre'
import YT from './YT'



// style={{background: "url('/static/img/bp1.png')", backgroundRepeat: 'round', backgroundSize: '20px 20px'}}

export default function Produse({prods}) {
    const tabs = [
        {
            hr: "plasma",
            nume: "Plasma",
            comp: <Plasma prods={prods}/>
        },
        {
            hr: "ff",
            nume: "Fier forjat",
            comp: <Fier prods={prods}/>
        },
        {
            hr: "inox",
            nume: "Inox",
            comp: <Inox prods={prods}/>
        }
    ]
    return (
    <div className="card">
        {/* <div className="card-header card-header-danger">
            <div className="nav-tabs-navigation">
                <div className="nav-tabs-wrapper">
                    <ul className="nav nav-tabs justify-content-center" data-tabs="tabs">
                        <li className="nav-item">
                            <a className="nav-link active" href="#acasa" data-toggle="tab">
                                Acasa
                            </a>
                        </li>
                        {
                            tabs.map((tab) => (
                                <li className="nav-item">
                                    <a className="nav-link" href={"#"+tab.hr} data-toggle="tab">
                                        {tab.nume}
                                    </a>
                                </li>
                            ))
                        }
                        <li className="nav-item">
                            <a className="nav-link" href="#aboutus" data-toggle="tab">
                            Despre noi
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div> */}
        <div className="card-body">
            <div style={{backgroundColor: 'rgba(255, 255, 255, 0.4)'}}>
                <div className="tab-content text-center">
                    <div className="tab-pane active" id="acasa">
                        <h2>Selecție proiecte finalizate</h2>
                        <div id="carouselImaginiPreferate" className="carousel slide" data-interval="3000" data-ride="carousel" style={{width: 100+'%', maxWidth: 800+'px', height: 'auto', marginLeft: 'auto', marginRight: 'auto'}}>
                            <ol className="carousel-indicators">
                                {prods.filter(prod => prod.home).sort((a, b) => b.prioritate - a.prioritate).map((im, index) => (
                                    <li data-target="#carouselImaginiPreferate" data-slide-to={index} {...(index == 0 ? { className: 'active' } : {})}></li>
                                ))}
                            </ol>
                            <div className="carousel-inner">
                                {prods.filter(prod => prod.home).sort((a, b) => b.prioritate - a.prioritate).map((im, index) => (
                                <div {...(index == 0 ? { className: 'carousel-item active' } : { className: 'carousel-item' })}>
                                    <img className="d-block w-100" src={im.imgs[0]} alt={im.nume}/>
                                    <div className="carousel-caption d-none d-md-block">
                                        <h4>
                                            {im.nume}
                                        </h4>
                                    </div>
                                </div>
                                ))}
                            </div>
                            <a className="carousel-control-prev" href="#carouselImaginiPreferate" role="button" data-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="sr-only">Anterior</span>
                            </a>
                            <a className="carousel-control-next" href="#carouselImaginiPreferate" role="button" data-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="sr-only">Urmatorul</span>
                            </a>
                        </div>
                        <h3 style={{color: '#F40009'}}><b>Asigurăm consultanță și proiectare gratuit</b></h3>
                        
                        <h2>Pas cu pas</h2>
                        <div id="caruselPasCuPas" className="carousel slide" data-interval="2000" data-ride="carousel" style={{width: 100+'%', maxWidth: 800+'px', height: 'auto', marginLeft: 'auto', marginRight: 'auto'}}>
                            <ol className="carousel-indicators">
                                <li data-target="#caruselPasCuPas" data-slide-to="0" className='active'></li>
                                <li data-target="#caruselPasCuPas" data-slide-to="1"></li>
                                <li data-target="#caruselPasCuPas" data-slide-to="2"></li>
                                <li data-target="#caruselPasCuPas" data-slide-to="3"></li>
                                <li data-target="#caruselPasCuPas" data-slide-to="4"></li>
                                <li data-target="#caruselPasCuPas" data-slide-to="5"></li>
                                <li data-target="#caruselPasCuPas" data-slide-to="6"></li>
                                <li data-target="#caruselPasCuPas" data-slide-to="7"></li>
                                <li data-target="#caruselPasCuPas" data-slide-to="8"></li>
                                <li data-target="#caruselPasCuPas" data-slide-to="9"></li>
                                <li data-target="#caruselPasCuPas" data-slide-to="10"></li>
                                <li data-target="#caruselPasCuPas" data-slide-to="11"></li>
                                <li data-target="#caruselPasCuPas" data-slide-to="12"></li>
                            </ol>
                            <div className="carousel-inner">
                                <div className= 'carousel-item active'>
                                    <img className="d-block w-100" src="/static/img/1.jpg" alt="Imagine"/>
                                    <div className="d-none d-md-block">
                                        <h4>
                                            
                                        </h4>
                                    </div>
                                </div>
                                <div className= 'carousel-item'>
                                    <img className="d-block w-100" src="/static/img/2.jpg" alt="Imagine"/>
                                    <div className="d-none d-md-block">
                                        <h4>
                                            
                                        </h4>
                                    </div>
                                </div>
                                <div className= 'carousel-item'>
                                    <img className="d-block w-100" src="/static/img/3.jpg" alt="Imagine"/>
                                    <div className="d-none d-md-block">
                                        <h4>
                                            
                                        </h4>
                                    </div>
                                </div>
                                <div className= 'carousel-item'>
                                    <img className="d-block w-100" src="/static/img/4.jpg" alt="Imagine"/>
                                    <div className="d-none d-md-block">
                                        <h4>
                                            
                                        </h4>
                                    </div>
                                </div>
                                <div className= 'carousel-item'>
                                    <img className="d-block w-100" src="/static/img/5.jpg" alt="Imagine"/>
                                    <div className="d-none d-md-block">
                                        <h4>
                                            
                                        </h4>
                                    </div>
                                </div>
                                <div className= 'carousel-item'>
                                    <img className="d-block w-100" src="/static/img/6.jpg" alt="Imagine"/>
                                    <div className="d-none d-md-block">
                                        <h4>
                                            
                                        </h4>
                                    </div>
                                </div>
                                <div className= 'carousel-item'>
                                    <img className="d-block w-100" src="/static/img/7.jpg" alt="Imagine"/>
                                    <div className="d-none d-md-block">
                                        <h4>
                                            
                                        </h4>
                                    </div>
                                </div>
                                <div className= 'carousel-item'>
                                    <img className="d-block w-100" src="/static/img/8.jpg" alt="Imagine"/>
                                    <div className="d-none d-md-block">
                                        <h4>
                                            
                                        </h4>
                                    </div>
                                </div>
                                <div className= 'carousel-item'>
                                    <img className="d-block w-100" src="/static/img/9.jpg" alt="Imagine"/>
                                    <div className="d-none d-md-block">
                                        <h4>
                                            
                                        </h4>
                                    </div>
                                </div>
                                <div className= 'carousel-item'>
                                    <img className="d-block w-100" src="/static/img/10.jpg" alt="Imagine"/>
                                    <div className="d-none d-md-block">
                                        <h4>
                                            
                                        </h4>
                                    </div>
                                </div>
                                <div className= 'carousel-item'>
                                    <img className="d-block w-100" src="/static/img/11.jpg" alt="Imagine"/>
                                    <div className="d-none d-md-block">
                                        <h4>
                                            
                                        </h4>
                                    </div>
                                </div>
                                <div className= 'carousel-item'>
                                    <img className="d-block w-100" src="/static/img/12.jpg" alt="Imagine"/>
                                    <div className="d-none d-md-block">
                                        <h4>
                                            
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <a className="carousel-control-prev" href="#caruselPasCuPas" role="button" data-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="sr-only">Anterior</span>
                            </a>
                            <a className="carousel-control-next" href="#caruselPasCuPas" role="button" data-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="sr-only">Urmatorul</span>
                            </a>
                        </div>
                        <hr/>
                        <div className='justify-content-center'>
                            <YT/>
                        </div>
                        
                    </div>
                    {
                        tabs.map((tab) => (
                            <div className="tab-pane" id={tab.hr}>
                                {tab.comp}
                            </div>
                        ))
                    }
                    <div className="tab-pane" id="aboutus">
                        <Despre/>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
    )
}
