import React from 'react'

export default function Inox({prods}) {
    let produse_filtrate = prods.filter(prod => prod.categ === 1)
    let subcategs = []
    for (let i = 0; i < produse_filtrate.length; i++) {
        if(subcategs.length == 0){
            subcategs.push({nume: produse_filtrate[i].subcateg, p: produse_filtrate[i].prioritate, imgs: produse_filtrate[i].imgs})
        }else{
            let gas = false;
            for(let j = 0; j<subcategs.length; j++)
                if(subcategs[j].nume === produse_filtrate[i].subcateg){
                    if(subcategs[j].p < produse_filtrate[i].prioritate){
                        subcategs[j].p = produse_filtrate[i].prioritate;
                        subcategs[j].imgs = produse_filtrate[i].imgs;
                    }
                    gas = true;
                    break;
                }
            if(!gas){
                subcategs.push({nume: produse_filtrate[i].subcateg, p: produse_filtrate[i].prioritate, imgs: produse_filtrate[i].imgs})
            }
        }
    }
    
    return (
        <div className="row">
            {subcategs.sort((a, b) => b.p - a.p).map((subcateg) => (
                <div class="card col-md-10 ml-auto col-xl-5 mr-auto">
                    <img class="card-img-top" src={subcateg.imgs[0]} rel="nofollow" alt="Imagine"/>
                    <div class="card-body">
                        <h3 class="card-text"><b>{subcateg.nume}</b></h3>
                        <a href={"/proiecte/inox/"+subcateg.nume.toLowerCase()} class="btn btn-primary">Mai multe</a>
                    </div>
              </div>
            ))}
        </div>
    )
}
