import React, { Component, useEffect, useState } from 'react'
import MHeader from '../MHeader'
import MFooter from '../MFooter'
import MNavbar from '../MNavbar'
import Features from './Features';
import Produse from './Produse';

import { Fab } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import Despre from './Despre';

export default function Acasa() {
  const [loaded, setLoaded] = useState(false);
  const [prods, setProds] = useState([])

    useEffect(() => {
      const requestOptions = {
        credentials: 'include',
        method: 'POST',
        mode: 'same-origin',
      };
      fetch('https://'+window.location.host+'/mart-api/get/', requestOptions)
        .then(response => response.json())
        .then(data => {
          // console.log(data.produse);
          setProds(data.produse)
        });
      }, [])

        return (
<div className="index-page sidebar-collapse">
  <MNavbar/>
  <MHeader/>

  <Fab
    mainButtonStyles={{color: 'white', backgroundColor: 'green'}}
    style={{color: 'green', bottom: 24, right: 24 }}
    icon={<i className="material-icons">call</i>}
    alwaysShowTitle={true}
    onClick={() => {window.open('tel:0743207067');}}
  />
  <div className="main" style={{paddingTop: 0+"px"}}>
    <div className="container">
      <div class="text-center">
        <br/>
        <blockquote class="blockquote">
          <p class="mb-0">Calitate și confort la un preț corect</p>
        </blockquote>
      </div>
    </div>
    <Produse prods={prods} style={{paddingTop: 0+"px"}}/>
    <Despre/>
  </div>
  <MFooter/>
</div>
        )
}

