import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Acasa from './components/Acasa/Acasa';
import CS from './components/CS/CS';

function App() {
  return (
    <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Acasa} />
          <Route exact path="/proiecte/:categ/:subcateg" component={CS} />
          <Route exact path="/proiecte/:categ" component={CS} />
          <Route exact path="/proiecte" component={CS} />
        </Switch>
      </BrowserRouter>
  );
}

export default App;
