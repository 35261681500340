import React from 'react'
import ReactPlayer from 'react-player/youtube'
export default function YT() {

    const url = "https://youtu.be/aDvwK0V6uOU";
  
      return <div className='player-wrapper'>
      <ReactPlayer
        className='react-player'
        url={url}
        loop={true}
        width='100%'
        height='100%'
      />
    </div>;
}
