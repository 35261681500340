import React from 'react'

export default function Carusel({nume, imagini}) {
    return (
        <div id={nume} class="carousel slide" data-interval="4000" data-ride="carousel" style={{width: 100+'%', maxWidth: 800+'px', height: 'auto', marginLeft: 'auto', marginRight: 'auto'}}>
            <ol class="carousel-indicators">
                {imagini.map((im, index) => (
                    <li data-target={"#"+nume} data-slide-to={index} {...(index == 0 ? { className: 'active' } : {})}></li>
                ))}
            </ol>
            <div class="carousel-inner">
                {imagini.map((im, index) => (
                <div {...(index == 0 ? { className: 'carousel-item active' } : { className: 'carousel-item' })}>
                    <img className="d-block w-100" src={im} alt="Imagine"/>
                </div>
                ))}
            </div>
            <a class="carousel-control-prev" href={"#"+nume} role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Anterior</span>
            </a>
            <a class="carousel-control-next" href={"#"+nume} role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Urmatorul</span>
            </a>
        </div>
    )
}
