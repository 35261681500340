import React from 'react'

export default function MHeader({pagina}) {
    return (
<div className="page-header header-filter" data-parallax="true" style={{paddingBottom: 0+"px", backgroundImage: "url('/static/img/Weld%201GIF.gif')"}}>
    <div className="container" style={{paddingBottom: 0+"px"}}>
        <div className="row" style={{paddingBottom: 0+"px"}}>
            <div className="col-md-8 ml-auto mr-auto">
            <div className="brand">
                <h1 className="titlu-mare">Metal Decorativ</h1>
                <h3>Confecții metalice decorative</h3>
            </div>
            </div>
        </div>
    </div>
</div>
    )
}
