import React, {Component, useEffect, useState} from 'react'
import MNavbar2 from '../MNavbar2'
import MHeader2 from '../MHeader2'
import MFooter from '../MFooter'
import Carusel from '../Carusel';
import { v4 as uuidv4 } from 'uuid';

import { Fab } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';

function capitalizeFirstLetter(string) {
    string = string.toLowerCase();
    if(string.includes("plasma")){
        string = "Debitare cu plasmă"
    }
    if(string.includes("fier")){
        string = "Fier forjat"
    }
    if(string.includes("inox")){
        string = "Confecții inox"
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

export default function CS({ match }) {
    const [prods, setProds] = useState([])

    let alegere = 0;
    if(match.params.categ === "inox"){
        alegere = 1;
    }
    if(match.params.categ === "plasma"){
        alegere = 2;
    }

    useEffect(() => {
      const requestOptions = {
        credentials: 'include',
        method: 'POST',
        mode: 'same-origin',
      };
      fetch('https://'+window.location.host+'/mart-api/get/', requestOptions)
        .then(response => response.json())
        .then(data => {
            // console.log(data.produse);
            if(match.params.subcateg === undefined){
                setProds(data.produse.filter(prod => prod.categ === alegere));
            }else{
                setProds(data.produse.filter(prod => prod.categ === alegere).filter(prod => prod.subcateg.toLowerCase() === match.params.subcateg.toLowerCase()));
            }
        });
      }, [])
    
    return (
        <div className="index-page sidebar-collapse">
            <MNavbar2/>
            <MHeader2/>
            <Fab
                mainButtonStyles={{color: 'white', backgroundColor: 'green'}}
                style={{color: 'green', bottom: 24, right: 24 }}
                icon={<i className="material-icons">call</i>}
                onClick={() => {window.open('tel:0743207067');}}
            />
            <div className="main">
                <div className="section section-basic">
                    <div className="container">
                    <h1 className="title text-center">{capitalizeFirstLetter(match.params.categ)}</h1>
                    {alegere==2 && <h2 className="text-center">(tablă decupată)</h2>}
                    
                    <h3 class="text-center">Prezentare proiecte {alegere==2 && "unice"} finalizate pentru inspirația dumneavoastră</h3>
                    {match.params.subcateg === undefined ? "" : <h4 className="text-center">{capitalizeFirstLetter(match.params.subcateg)}</h4>}
                        <div className="section text-center">
                        <nav aria-label="breadcrumb" role="navigation">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/">Acasa</a></li>
                                {match.params.subcateg === undefined ? <li className="breadcrumb-item active" aria-current="page">{capitalizeFirstLetter(match.params.categ)}</li> : <li className="breadcrumb-item"><a href={"/proiecte/"+match.params.categ}>{capitalizeFirstLetter(match.params.categ)}</a></li>}
                                {match.params.subcateg === undefined ? "" : <li className="breadcrumb-item active" aria-current="page">{capitalizeFirstLetter(match.params.subcateg)}</li>}
                            </ol>
                        </nav>
                            <div className="row">
                                {prods.sort((a, b) => b.prioritate - a.prioritate).map((prod) => (
                                    <div {...(prod.pref ? { className: 'card col-md-10 ml-auto col-xl-5 mr-auto card-nav-tabs' } : { className: 'card col-md-10 ml-auto col-xl-5 mr-auto'})} >
                                        {prod.pref ? <div class="card-header card-header-warning">
                                            Produs favorit
                                        </div> : <nodiv/>}
                                        {prod.pref ? <br/> : ""}
                                        <Carusel nume={"carusell"+uuidv4()} imagini={prod.imgs} />
                                        <div className="card-body">
                                            <h3 className="card-text"><b>{prod.nume}</b></h3>
                                            {prod.desc.length > 4 && <h5>{prod.desc}</h5>}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <MFooter/>
        </div>
    )
}
