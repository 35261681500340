import React from 'react'

export default function Plasma({prods}) {
    let produse_filtrate = prods.filter(prod => prod.categ === 2)
    let subcategs = []
    for (let i = 0; i < produse_filtrate.length; i++) {
        if(subcategs.length == 0){
            subcategs.push({nume: produse_filtrate[i].subcateg, p: produse_filtrate[i].prioritate, imgs: produse_filtrate[i].imgs})
        }else{
            let gas = false;
            for(let j = 0; j<subcategs.length; j++)
                if(subcategs[j].nume === produse_filtrate[i].subcateg){
                    if(subcategs[j].p < produse_filtrate[i].prioritate){
                        subcategs[j].p = produse_filtrate[i].prioritate;
                        subcategs[j].imgs = produse_filtrate[i].imgs;
                    }
                    gas = true;
                    break;
                }
            if(!gas){
                subcategs.push({nume: produse_filtrate[i].subcateg, p: produse_filtrate[i].prioritate, imgs: produse_filtrate[i].imgs})
            }
        }
    }

    return (
        <div>
            {/* <div class="card card-nav-tabs">
                <div class="card-header card-header-warning">
                    Produsul favorit
                </div>

                <div class="card-body">
                <div id="carouselImaginiTest" class="carousel slide text-right" data-ride="carousel" style={{width: 100+'%', maxWidth: 800+'px', height: 'auto', marginLeft: 'auto', marginRight: 'auto'}}>
                        <ol class="carousel-indicators">
                            {prods.filter(prod => prod.pref).sort((a, b) => b.prioritate - a.prioritate).map((im, index) => (
                                <li data-target="#carouselImaginiTest" data-slide-to={index} {...(index == 0 ? { className: 'active' } : {})}></li>
                            ))}
                        </ol>
                        <div class="carousel-inner">
                            {prods.filter(prod => prod.pref).sort((a, b) => b.prioritate - a.prioritate).map((im, index) => (
                            <div {...(index == 0 ? { className: 'carousel-item active' } : { className: 'carousel-item' })}>
                                <img className="d-block w-100" src={im.imgs} alt={im.nume}/>
                                <div class="carousel-caption d-none d-md-block">
                                    <h4>
                                        {im.nume}
                                    </h4>
                                </div>
                            </div>
                            ))}
                        </div>
                        <a class="carousel-control-prev" href="#carouselImaginiTest" role="button" data-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="sr-only">Anterior</span>
                        </a>
                        <a class="carousel-control-next" href="#carouselImaginiTest" role="button" data-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="sr-only">Urmatorul</span>
                        </a>
                    </div>
                    <h4 class="card-title text-left">Orice imagine pe gardul tau</h4>
                    <p class="card-text text-left">Incarcati imaginea pe butonul de mai jos.</p>
                </div>
            </div> */}
            <div className="row">
                {subcategs.sort((a, b) => b.p - a.p).map((subcateg) => (
                    <div class="card col-md-10 ml-auto col-xl-5 mr-auto">
                        <img class="card-img-top" src={subcateg.imgs[0]} rel="nofollow" alt="Imagine"/>
                        <div class="card-body">
                            <h3 class="card-text"><b>{subcateg.nume}</b></h3>
                            <a href={"/proiecte/plasma/"+subcateg.nume.toLowerCase()} class="btn btn-primary">Mai multe</a>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
