import React from 'react'

export default function MFooter() {
    return (
        <footer className="footer" data-background-color="black">
                <div className="container">
                <nav className="float-left">
                    <ul>
                    <li>
                        <a onClick={() => window.open('tel:0743207067')}>
                        Contacteaza-ne
                        </a>
                    </li>
                    </ul>
                </nav>
                </div>
        </footer>
    )
}
