import React from 'react'

import GoogleMapReact from 'google-map-react';

export default function Despre() {
    const pozitie = [45.181471, 28.775212];

    return (
        <div className="card card-body text-center">
            <h2>Informatii</h2>
            <h3><b>Adresa: </b>Str Viitorului Nr 1 A</h3>
            <h3><b>Contact: </b>0743207067</h3>
            <h4>
              <a href="https://m.facebook.com/pages/category/Home-Improvement/Atelier-confectii-metalice-Tulcea-1872073759724925/?locale2=ro_RO" target="_blank"><i className="fa fa-facebook-square"> Atelier confectii metalice Tulcea</i></a>
            </h4>
            <div style={{ height: '75vh', width: '100%' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: "AIzaSyCpzuBnqgM5Utj94noRR7kRS3IQ4FtTuUE"}}
                    defaultCenter={{
                        lat: 45.181471,
                        lng: 28.775212
                    }}
                    defaultZoom={14}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map, maps }) => {
                        new maps.Marker({
                            position: { lat: 45.181471, lng: 28.775212 },
                            map,
                            title: "Sediul nostru",
                        });
                    }}
                    />
            </div>
        </div>
    )
}
